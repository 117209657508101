import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"


const WhatIsRj = () => {



    return (
        <Layout>
            <SEO title="What is RJ?" />
            <div className="content-wrapper">
                <div className="page-intro">
                    <h1>What is Restorative Justice?</h1>
                    <p className='lead'>...sounds kind of complicated right?<br /> For now, don't worry about the name, let's just look at what it is.</p>
                </div>
                <div className="container-fluid mb-4">
                    <div className="whatIsRj">
                        <div>
                            <p className="mb-2">We all live in communities - be it cities, towns, neighborhoods, our workplace, our friends, or even our family. Isn't it perfect when there is peace and harmony in these? Of course! But conflict, disagreement, and harm will also happen.</p>
                            <p className="mb-2">One way to bring back harmony is to remove that conflict, disagreement, or harm. We can simply stop talking to a friend, leave a household, or put someone who has committed a crime in prison. This is a quick solution but often leaves friends, family, and victims of crime wondering, “why did this happen?”</p>
                            <p className="mb-2">There is also a high cost associated with just removing conflict. Prison costs are significant, divorce is expensive, firing an employee can result in a civil claim, losing a friend carries emotional costs. If broken relationships can be restored, these costs can be avoided. It takes time, effort, and commitment to build relationships...isn't it worth the same effort to restore and even rebuild them?</p>
                            <p className="mb-2">In every disagreement, conflict, or instance of harm there will be someone who said or did something wrong and someone who heard or felt it. Once the harm is done, the relationship can become broken and it is usually difficult for the harmed to understand why it happened, or the harmer to explain and apologise. What usually happens is that our impulse for punishment kicks-in, but this can divide communities. We often remove people altogether and although it seems like peace is achieved, it leaves unanswered questions and emotional pain.
                            </p>
                        </div>
                        <div className="whatIsRjImg">
                            <StaticImage src="../images/what_is_RJ_thinker.png" alt='What is RJ Thinker' />
                        </div>
                    </div>

                    <p className="mb-2">Those clever folks in universities and governments have studied the benefits of rebuilding relationships rather than simply removing community members and have found some really interesting stuff! First, it is much, much cheaper to try to restore a relationship than to end it. The British government found that for criminal offenses, it costs eight times less to use restorative justice than a traditional court case process. In that same study, they also found that people going through a restorative approach were far less likely to re-offend. The icing on the cake in this study was that victims, or those harmed, felt much more satisfied with the outcome when compared to a traditional criminal justice procedure. In other words, it is more cost effective, it helps stop harmful behaviour repeating and those that were harmed feel better about the outcome.</p>

                    <p className="mb-2">It is important to also recognise that you cannot restore all relationships. Sometimes the harm is too traumatic or the harmer is unwilling to take responsibility. In these circumstances, the community needs to remove the harmer and restorative approaches are not appropriate.  However, if the harmer and harmed are willing to try to restore the relationship, a restorative justice approach will always have better outcomes.</p>

                    <p className="mb-2">“But what is it?!” I hear you ask.</p>

                    <p className="mb-2">It is when someone who has committed a harm and the person who has been harmed come together to discuss what happened. The person who harmed will better understand the pain they have caused and the person harmed will get a better understanding of “why?”. Ultimately, the harmer will apologise and do something to help restore the relationship. It really is that simple, but there is power in that simplicity. The harmed gets closure and the harmer understands the pain they have caused. This is a process that can be used in all walks of life: in criminal justice, in schools and universities, in companies, and between friends. We just need to use it more and both understand and reap the benefits of keeping our communities together.</p>

                    <p>So don't worry about the name, just remember that Restorative Justice is better for all of us.</p>

                </div>
            </div>

        </Layout>
    )
}

export default WhatIsRj
